<template>
  <div :class="$style.container">
    <component :is="padless ? Fragment : 'div'" :class="$style.pad">
      <slot @close="close" />
    </component>
    <button
      :class="[$style.close, { [$style.circular]: circularCloseButton }]"
      @click="close"
      v-if="!hideCloseButton"
    >
      <ui-icon name="close" />
    </button>
  </div>
</template>

<script setup>
import UiIcon from '~/components/ui/icon/index.vue'
import Fragment from '~/components/common/fragment/index.vue'

const $emit = defineEmits(['close'])
const close = (...args) => $emit('close', ...args)
defineProps({
  hideCloseButton: {
    type: Boolean,
    default: false
  },
  /**
   * Не добавлять набивку (обертку с отступами и мин. высотой) к содержимому
   */
  padless: {
    type: Boolean,
    default: false
  },
  circularCloseButton: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="scss" module>
.container {
  position: relative;
  cursor: default;

  border-radius: 1.6em;
  background: var(--t-app-l2-bg);
  color: #fff;
  width: 75.7em;
  max-width: calc(100vw - 4em);

  margin: 8em;
  @include down(md) {
    margin: 4em;
  }
  @include down(sm) {
    margin: 1.6em;
  }

  @include down(md) {
    border-radius: 1.6em;
    border: 0 none;
    width: 70em;
    min-height: 100%;
    max-width: none;
  }
  @include down(sm) {
    border-radius: 0.4em;
    width: calc(100vw - 4em);
  }

  @include down(sm) {
    width: calc(100vw - 3.2em);
  }
}
.pad {
  border-radius: inherit;
  padding: 4.8em;
  min-height: 20em;
  color: var(--style-text);
  background: var(--style-bg);

  @include down(md) {
    padding: 6em 4em 4em;
  }

  @include down(sm) {
    padding: 2em;
  }
}
.close {
  position: absolute;
  top: 2.4em;
  right: 2.4em;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  transition: transform 0.2s;
  border-radius: 0;
  background: transparent;
  border: 0 none;
  @include down(md) {
    top: 2em;
    right: 2.7em;
  }
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
  &:hover {
    transform: scale(1.4);
  }

  &.circular {
    width: 3.2em;
    height: 3.2em;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
    right: 4em;
    top: 2.8em;

    @include down(sm) {
      right: 1.6em;
    }

    svg {
      width: 1.8em;
      height: 1.8em;
    }
  }
}
</style>
